<script>
import $ from "jquery"
import _ from "lodash"
import GlobalVue from '@libs/Global.vue';
import Gen from '@libs/Gen';

export default {
	extends: GlobalVue,
  props: {
    type: String,
    value: String,
    label: String,
    uploadType: {default: ""},
    attr:{default:()=>({})},
    param: {default:()=>({thumbnail:false})},
    galleryParams: {default:()=>({})},
    config: Object,
    noHelp: Boolean,
    noLoading: {type:Boolean,default:()=>(true)},
    noPreview: Boolean,
    block: Boolean,
    btnClass: String, 
    readonly: Boolean, 
    fileName: String
  },
  data: function () {
    return {
      uploading: false,
      error: false,
      inputFile: null,
      imgFile: String,
    }
  },
  computed:{
    fields(){ return this.$root.fields },
    conf(){ return _.assign(_.clone(this.$root.app.uploader[this.type]),this.config||{}) },
    base(){ return this.$root.apiUrl },
    accept(){
      return "."+this.conf.rule_type.split(",").join(",.")
    },
    uploadText(){
      if(this.value) return "Update"
      return this.uploadType == "gallery" ? "Select" : "Upload"
    }
  },
  mounted(){
    this.init()
    this.inputFile = $("<input type='file' accept='"+this.accept+"'>")[0]
    this.inputFile.onclick = ()=>{ this.inputFile.value = '' }
    this.inputFile.onchange = this.onUpload
    global.inputFile = this.inputFile
    
    if(this.$slots.btn) this.$slots.btn[0].elm.onclick = this.uploadClick
  },
  methods: {
    init(){
      if(!this.conf) return
      this.param.type = this.type
      if (!this.uploadType){
        this.param.uploadType = this.conf.img_ratio ? "cropping" : "upload"
      }else{
        this.param.uploadType = _.clone(this.uploadType)
      }
    },
    uploadClick(){
      if(this.uploadType=='gallery') return this.openGallery()
      return this.inputFile.click()
    },
    openGallery(){
      global.FileManager.open((files)=>{
        if(files.length){
          var arrayValue = [];
          files.forEach((v)=>{
            arrayValue.push(v.pathfile)
          })
          this.$emit('input', arrayValue)
        }else{
          this.$emit('input', files.pathfile);
        }
        this.$emit('response', this)
      }, _.assign({type:this.type}, this.galleryParams))
    },
    removeUpload(){
      this.$emit('input', "");
    },
    onUpload(e){
      this.fileData = $(this.inputFile).prop('files')[0];
      this.conf.fileType = this.fileData.type;
      this.fileType = this.fileData.name.substr(this.fileData.name.lastIndexOf('.') + 1).toLowerCase();
      if (this.conf.rule_size){
        var reader = new FileReader();
        reader.onload = () => {
          var img = new Image;
          img.onload = () => {
            this.img = img
            this.uploadProcess(e)
          }
          img.src = reader.result
        };
        reader.readAsDataURL(this.fileData);
      }else{
        this.uploadProcess(e)
      }
    },
    uploadProcess(){
      // Validation
      this.error = false;
      if (this.conf['rule_type'].indexOf(this.fileType) == -1) {
        this.error = 'File type must be ' + this.conf['rule_type'] + ' type.';
      }
      if (this.fileData.size > this.$root.app.uploader['max_image_size']) {
        this.error = 'Max file size is '+this.$root.app.uploader['max_image_size'].bytesToSize();
      }
      // if(this.conf.rule_size) if(this.img.naturalWidth>=this.conf.rule_size[0]||this.img.naturalHeight>=this.conf.rule_size[1]||this.type ==="hero"){
      //   this.param.uploadType = "cropping"
      // }

      // if (this.conf.rule_size) if(this.img.naturalWidth<this.conf.rule_size[0]||this.img.naturalHeight<this.conf.rule_size[1]){
      //     this.error = 'Minimum image size is '+this.conf.rule_size[0]+" x "+this.conf.rule_size[1]+" Pixel."
      // }

      if (this.error){
        return alert(this.error);
      }

      // Automatic upload if not image
      if(("jpg,jpeg,png").indexOf(this.fileType) < 0) this.param.uploadType = "upload";

      // Quick Image Upload Filter
      if(this.param.uploadType != "upload"){
        let reader = new FileReader()
        reader.onload = (e)=>{
          this.imageFilter(e.target.result, this.fileData.name)
        }
        reader.readAsDataURL(this.fileData)
        return 
      }

      let formData = new FormData();
      formData.append(this.type=="editor" ? 'upload' : 'file', this.fileData);
      this.param.pageType = this.type;
      $.each(this.param, (k, v) => {
        formData.append(k, v);
      });
      let query = {
        token: Gen.getCookie("bo_auth"),
      }
      this.uploading = true
      $.ajax({
        url: this.apiUrl + "/api/app/ajax/upload?"+Gen.objectToQuery(query),
        headers:Gen.apiHeader(),
        type: "POST",
        data: formData,
        enctype: 'multipart/form-data',
        processData: false, // tell jQuery not to process the data
        contentType: false, // tell jQuery not to set contentType
        xhr: ()=>{
          let xhr = new window.XMLHttpRequest();
          let ajax_progress = $('<p style="margin-bottom:0px;" class="label_progress"></p><div class="progress" style="margin:0px 0px 0px;height:20px;"><div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style="width:3%"></div></div>');
          $(this.$el).find(".ajax_progress").html(ajax_progress);
          //Upload progress
          xhr.upload.addEventListener("progress", (evt)=>{
            if (evt.lengthComputable) {
              let percentComplete = evt.loaded / evt.total;
              let label_process = "File Upload: "+(evt.loaded/1000)+  "Kb / " + (evt.total/1000)+"Kb.";
              // Do something with upload progress
              ajax_progress.find('.progress-bar').width((percentComplete*100)+"%");
              $('.label_progress').text(label_process);
              if(percentComplete == 1){
                setTimeout(()=>{ ajax_progress.fadeOut(500); }, 600);
                setTimeout(()=>{ $(this.$el).find(".ajax_progress").html(""); }, 1100);
              }
            }
          }, false);
          return xhr;
        }
      }).done((resp) => {
        this.uploading = false
        if (this.param.uploadType == "upload") {
          this.$emit('input', resp.pathfile);
          this.$emit('resp', resp)
          this.$emit('response', this)
          return;
        } 
        return this.imageFilter(resp.targetfile, resp.filename)
      });
    },
    imageFilter(img, filename){
      let query = {
        token: Gen.getCookie("bo_auth"),
      }
      global.ImageFilter.open(img, (data) => {
        let form_data = new FormData();
        form_data.append("file", data);
        form_data.append("path", this.type);
        form_data.append("image_name", filename);
        this.$emit("beforeDone", this)
        $.ajax({
          url: this.apiUrl + "/api/app/ajax/upload_filter?"+Gen.objectToQuery(query),
          headers:Gen.apiHeader(),
          type: "POST",
          data: form_data,
          enctype: 'multipart/form-data',
          processData: false,  // tell jQuery not to process the data
          contentType: false,
          success: (resp) => {
            this.$emit('input', resp.pathfile);
            this.$emit('response', this);
            this.imgFile=resp.targetfile;
            if($("div").hasClass("avatar_prof")){
              $('.avatar_prof').html('<img src="'+(resp.targetfile || '').removeWebp()+'" alt="user">');
            }
            // double crop
          }
        });
      }, this.conf);
    },
  },
  watch:{
    type(){
      setTimeout(()=>{ this.init() },300)
    },
    '$root.app.uploader'(){
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container{
  position: relative;
  .image-preview{
    position: relative;
    .remove{
      position: absolute;
      display: block;
      background: #ff6767;
      width: 18px;
      height: 18px;
      color: #fff;
      padding: 0px 5px;
      line-height: 15px;
      border-radius: 50%;
      top: -5px;
      right: 5px;
    }
  }
}
.ajax_progress{
  height: 20px;
  right: 0;
  width: 40%;
  top: 0;
  display: inline-grid;
  position: absolute;
}
.upload-btn{
  position: relative;
  input[type=file]{
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
.upload_wrapper{
  vertical-align: middle;
}
.upload_wrapper.upload_w100,.upload_wrapper.upload_w100 .file-upload-box{
  display:block;
}
</style>

<template>
  <div class="upload-container" v-if="conf">
    <slot name="preview" v-if="!noPreview">
      <div class="wrap_preview_photo image-preview" v-if="value">
        <div class="thumb_img_prev d-flex" style="padding-top: 1em;" v-if="fileName === 'file'">
          <a :href="uploader((value || '').removeWebp())" target="_blank" rel="noopener noreferrer" class="mr-2" v-tooltip="'Open File Preview'">Open File</a>
          <a href="javascript:;" @click="removeUpload" class="remove" style="position: static;" v-if="value&&!readonly">x</a>
        </div>
        <div class="thumb_img_prev" v-else>
          <img :src="uploader((value || '').removeWebp())" class="img-thumbnail"/>
          <a href="javascript:;" @click="removeUpload" class="remove" v-if="value&&!readonly">x</a>
          <!-- <img :src="uploader(value)" class="img-thumbnail"/> -->
        </div>
      </div>
      <!--  -->
        
        <!-- <div v-if="!param.thumbnail">
          <ImagePreview class="mr10" :src="uploader(value)" v-if="value"></ImagePreview>
          <a href="javascript:;" @click="removeUpload" class="remove" v-if="value&&!readonly">x</a>
        </div>
        <div v-else>
          <VImg class="image_thumb" v-for="(v,k) in value" :key="k" :src="uploader(v)"></VImg>
        </div>
        -->

    </slot>
    <slot name="btn" v-if="!readonly">
      <div href="javascript:;" :class="'upload_wrapper '+(btnClass||'')" @click="uploadClick">
        <div class="file-upload-box">
          <label class="file-upload__label">
            <div class="wrap_text">
              <slot 
                v-if="!uploading" 
                name="label" 
                :uploadText="uploadText">
                  <i class="fas fa-image"></i> 
                  <p>{{uploadText}} {{label||'File'}}</p>
              </slot>
              <span v-if="uploading">
                <i class="fas fa-image"></i> <p>Uploading...</p>
              </span>
            </div>
          </label>
        </div>
      </div>
    </slot>
    <div v-if="!noLoading" class="ajax_progress" style=""> </div>
    <slot name="help">
      <div class="wrap_info" v-if="!noHelp&&!readonly">
        <p>Requirement Size ({{conf.imgsize_recommend}})</p>
        <p>Format : <span class="text-uppercase">{{conf.rule_type}}</span></p>
        <p>Maximal file size {{$root.app.uploader["max_image_size"].bytesToSize()}}</p>
      </div>
    </slot>
  </div>
</template>